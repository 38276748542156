import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4b44e8c8 = () => interopDefault(import('../src/pages/about.vue' /* webpackChunkName: "pages/about" */))
const _d69e25d0 = () => interopDefault(import('../src/pages/business/index.vue' /* webpackChunkName: "pages/business/index" */))
const _68f427be = () => interopDefault(import('../src/pages/c/index.vue' /* webpackChunkName: "pages/c/index" */))
const _ff348d34 = () => interopDefault(import('../src/pages/complaint.vue' /* webpackChunkName: "pages/complaint" */))
const _76def748 = () => interopDefault(import('../src/pages/cv.vue' /* webpackChunkName: "pages/cv" */))
const _2ac87f70 = () => interopDefault(import('../src/pages/disclaimer.vue' /* webpackChunkName: "pages/disclaimer" */))
const _767c08cc = () => interopDefault(import('../src/pages/feedback.vue' /* webpackChunkName: "pages/feedback" */))
const _3b1a98e6 = () => interopDefault(import('../src/pages/hello.vue' /* webpackChunkName: "pages/hello" */))
const _287227d6 = () => interopDefault(import('../src/pages/help.vue' /* webpackChunkName: "pages/help" */))
const _7099f288 = () => interopDefault(import('../src/pages/howitworks.vue' /* webpackChunkName: "pages/howitworks" */))
const _37b1a684 = () => interopDefault(import('../src/pages/imprint.vue' /* webpackChunkName: "pages/imprint" */))
const _345293f6 = () => interopDefault(import('../src/pages/invited.vue' /* webpackChunkName: "pages/invited" */))
const _08e4390c = () => interopDefault(import('../src/pages/jobalarm/index.vue' /* webpackChunkName: "pages/jobalarm/index" */))
const _70d26cae = () => interopDefault(import('../src/pages/jobs/index.vue' /* webpackChunkName: "pages/jobs/index" */))
const _3badeb06 = () => interopDefault(import('../src/pages/karriere/index.vue' /* webpackChunkName: "pages/karriere/index" */))
const _56a3d562 = () => interopDefault(import('../src/pages/lebenslauf-erstellen.vue' /* webpackChunkName: "pages/lebenslauf-erstellen" */))
const _71950bb8 = () => interopDefault(import('../src/pages/linkedInCallBack.vue' /* webpackChunkName: "pages/linkedInCallBack" */))
const _41f34e81 = () => interopDefault(import('../src/pages/press/index.vue' /* webpackChunkName: "pages/press/index" */))
const _315292fa = () => interopDefault(import('../src/pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _dc3d1446 = () => interopDefault(import('../src/pages/references.vue' /* webpackChunkName: "pages/references" */))
const _b0b6809c = () => interopDefault(import('../src/pages/referral.vue' /* webpackChunkName: "pages/referral" */))
const _5e2307b7 = () => interopDefault(import('../src/pages/snapchat.vue' /* webpackChunkName: "pages/snapchat" */))
const _1f62974a = () => interopDefault(import('../src/pages/support.vue' /* webpackChunkName: "pages/support" */))
const _6d5035f5 = () => interopDefault(import('../src/pages/team/index.vue' /* webpackChunkName: "pages/team/index" */))
const _458971f7 = () => interopDefault(import('../src/pages/values.vue' /* webpackChunkName: "pages/values" */))
const _83024e8e = () => interopDefault(import('../src/pages/video/index.vue' /* webpackChunkName: "pages/video/index" */))
const _1d7a9de6 = () => interopDefault(import('../src/pages/business/contact.vue' /* webpackChunkName: "pages/business/contact" */))
const _a043e118 = () => interopDefault(import('../src/pages/business/downloads/index.vue' /* webpackChunkName: "pages/business/downloads/index" */))
const _4d9b1af4 = () => interopDefault(import('../src/pages/business/events/index.vue' /* webpackChunkName: "pages/business/events/index" */))
const _490eda42 = () => interopDefault(import('../src/pages/business/guide.vue' /* webpackChunkName: "pages/business/guide" */))
const _21f9a08c = () => interopDefault(import('../src/pages/business/guide-advertisement.vue' /* webpackChunkName: "pages/business/guide-advertisement" */))
const _13ab4fc8 = () => interopDefault(import('../src/pages/business/hr-tipps/index.vue' /* webpackChunkName: "pages/business/hr-tipps/index" */))
const _3b4b39d0 = () => interopDefault(import('../src/pages/business/partners/index.vue' /* webpackChunkName: "pages/business/partners/index" */))
const _bf3a8148 = () => interopDefault(import('../src/pages/business/podcasts/index.vue' /* webpackChunkName: "pages/business/podcasts/index" */))
const _77e9d68c = () => interopDefault(import('../src/pages/business/pricing.vue' /* webpackChunkName: "pages/business/pricing" */))
const _f45b7ee4 = () => interopDefault(import('../src/pages/business/products.vue' /* webpackChunkName: "pages/business/products" */))
const _014cd2de = () => interopDefault(import('../src/pages/business/recruiting/index.vue' /* webpackChunkName: "pages/business/recruiting/index" */))
const _8dd93a7c = () => interopDefault(import('../src/pages/business/signup.vue' /* webpackChunkName: "pages/business/signup" */))
const _a69aebd6 = () => interopDefault(import('../src/pages/business/support.vue' /* webpackChunkName: "pages/business/support" */))
const _054d7355 = () => interopDefault(import('../src/pages/business/tup.vue' /* webpackChunkName: "pages/business/tup" */))
const _5530fd94 = () => interopDefault(import('../src/pages/business/webinars/index.vue' /* webpackChunkName: "pages/business/webinars/index" */))
const _74c9194e = () => interopDefault(import('../src/pages/jobalarm/settings.vue' /* webpackChunkName: "pages/jobalarm/settings" */))
const _79903cf5 = () => interopDefault(import('../src/pages/lp/ausbildung-oder-abitur-quiz.vue' /* webpackChunkName: "pages/lp/ausbildung-oder-abitur-quiz" */))
const _083481f6 = () => interopDefault(import('../src/pages/lp/ausbildungs-quiz.vue' /* webpackChunkName: "pages/lp/ausbildungs-quiz" */))
const _499248de = () => interopDefault(import('../src/pages/lp/brutto-netto-rechner.vue' /* webpackChunkName: "pages/lp/brutto-netto-rechner" */))
const _57474c75 = () => interopDefault(import('../src/pages/lp/gehltsverhandlung-quiz.vue' /* webpackChunkName: "pages/lp/gehltsverhandlung-quiz" */))
const _03579c56 = () => interopDefault(import('../src/pages/lp/haerteste-jobs-quiz.vue' /* webpackChunkName: "pages/lp/haerteste-jobs-quiz" */))
const _57789580 = () => interopDefault(import('../src/pages/lp/home-office-typ-quiz.vue' /* webpackChunkName: "pages/lp/home-office-typ-quiz" */))
const _74329938 = () => interopDefault(import('../src/pages/lp/jobinterviewtraining.vue' /* webpackChunkName: "pages/lp/jobinterviewtraining" */))
const _5533ea22 = () => interopDefault(import('../src/pages/lp/jobwechsel-quiz.vue' /* webpackChunkName: "pages/lp/jobwechsel-quiz" */))
const _d34f59f2 = () => interopDefault(import('../src/pages/lp/karriere-quiz.vue' /* webpackChunkName: "pages/lp/karriere-quiz" */))
const _8b716372 = () => interopDefault(import('../src/pages/lp/kellner-quiz.vue' /* webpackChunkName: "pages/lp/kellner-quiz" */))
const _be95d50a = () => interopDefault(import('../src/pages/lp/lehre-oder-studium-quiz.vue' /* webpackChunkName: "pages/lp/lehre-oder-studium-quiz" */))
const _f4078d34 = () => interopDefault(import('../src/pages/lp/lehre-quiz.vue' /* webpackChunkName: "pages/lp/lehre-quiz" */))
const _4a9c86e2 = () => interopDefault(import('../src/pages/lp/minijob-quiz.vue' /* webpackChunkName: "pages/lp/minijob-quiz" */))
const _2c207323 = () => interopDefault(import('../src/pages/lp/nebenjob-quiz.vue' /* webpackChunkName: "pages/lp/nebenjob-quiz" */))
const _f109b298 = () => interopDefault(import('../src/pages/lp/unterrichtsmaterial.vue' /* webpackChunkName: "pages/lp/unterrichtsmaterial" */))
const _4fcc0fb5 = () => interopDefault(import('../src/pages/lp/vier-tage-woche-quiz.vue' /* webpackChunkName: "pages/lp/vier-tage-woche-quiz" */))
const _5784d4b6 = () => interopDefault(import('../src/pages/lp/virtualconnect.vue' /* webpackChunkName: "pages/lp/virtualconnect" */))
const _58b9b6b5 = () => interopDefault(import('../src/pages/lp/vorstellungsgespraech-check.vue' /* webpackChunkName: "pages/lp/vorstellungsgespraech-check" */))
const _10d768c4 = () => interopDefault(import('../src/pages/press/press-links.vue' /* webpackChunkName: "pages/press/press-links" */))
const _61b848f7 = () => interopDefault(import('../src/pages/press/press-releases.vue' /* webpackChunkName: "pages/press/press-releases" */))
const _1a680ede = () => interopDefault(import('../src/pages/business/agreement/application.vue' /* webpackChunkName: "pages/business/agreement/application" */))
const _c766e1c4 = () => interopDefault(import('../src/pages/business/lp/active-sourcing.vue' /* webpackChunkName: "pages/business/lp/active-sourcing" */))
const _bb80aeb2 = () => interopDefault(import('../src/pages/business/lp/do-it-jobs.vue' /* webpackChunkName: "pages/business/lp/do-it-jobs" */))
const _796bf0c1 = () => interopDefault(import('../src/pages/business/lp/employer-branding-card.vue' /* webpackChunkName: "pages/business/lp/employer-branding-card" */))
const _16ec844f = () => interopDefault(import('../src/pages/business/lp/inserate-schalten.vue' /* webpackChunkName: "pages/business/lp/inserate-schalten" */))
const _d79bb64e = () => interopDefault(import('../src/pages/business/lp/kandidaten-suchen.vue' /* webpackChunkName: "pages/business/lp/kandidaten-suchen" */))
const _306791c8 = () => interopDefault(import('../src/pages/business/lp/mitarbeiter-finden.vue' /* webpackChunkName: "pages/business/lp/mitarbeiter-finden" */))
const _5fe120f4 = () => interopDefault(import('../src/pages/business/lp/mitarbeiter-suche.vue' /* webpackChunkName: "pages/business/lp/mitarbeiter-suche" */))
const _11402d1f = () => interopDefault(import('../src/pages/business/lp/recruiter-assistent.vue' /* webpackChunkName: "pages/business/lp/recruiter-assistent" */))
const _38a7a1ec = () => interopDefault(import('../src/pages/business/lp/termin-vereinbaren.vue' /* webpackChunkName: "pages/business/lp/termin-vereinbaren" */))
const _47f9dea5 = () => interopDefault(import('../src/pages/business/lp/testimonials.vue' /* webpackChunkName: "pages/business/lp/testimonials" */))
const _710a1748 = () => interopDefault(import('../src/pages/business/lp/unsere-loesungen.vue' /* webpackChunkName: "pages/business/lp/unsere-loesungen" */))
const _6afcc38d = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _289a4f9f = () => interopDefault(import('../src/pages/business/downloads/_alias.vue' /* webpackChunkName: "pages/business/downloads/_alias" */))
const _29d24ecd = () => interopDefault(import('../src/pages/business/events/_alias.vue' /* webpackChunkName: "pages/business/events/_alias" */))
const _617b2a56 = () => interopDefault(import('../src/pages/business/hr-tipps/_tag.vue' /* webpackChunkName: "pages/business/hr-tipps/_tag" */))
const _21a25f39 = () => interopDefault(import('../src/pages/business/invite/_invitecode.vue' /* webpackChunkName: "pages/business/invite/_invitecode" */))
const _45a8707b = () => interopDefault(import('../src/pages/business/partners/_alias.vue' /* webpackChunkName: "pages/business/partners/_alias" */))
const _48ab9cb7 = () => interopDefault(import('../src/pages/business/podcasts/_alias.vue' /* webpackChunkName: "pages/business/podcasts/_alias" */))
const _6ee0a7bc = () => interopDefault(import('../src/pages/business/recruiting/_alias.vue' /* webpackChunkName: "pages/business/recruiting/_alias" */))
const _9781d3c6 = () => interopDefault(import('../src/pages/business/webinars/_alias.vue' /* webpackChunkName: "pages/business/webinars/_alias" */))
const _0a593b8b = () => interopDefault(import('../src/pages/business/feedback/_job/_code.vue' /* webpackChunkName: "pages/business/feedback/_job/_code" */))
const _98cc0ff0 = () => interopDefault(import('../src/pages/business/mon/_job/_code.vue' /* webpackChunkName: "pages/business/mon/_job/_code" */))
const _621b4435 = () => interopDefault(import('../src/pages/business/startjob/_job/_code.vue' /* webpackChunkName: "pages/business/startjob/_job/_code" */))
const _624fddb9 = () => interopDefault(import('../src/pages/business/stopjob/_job/_code.vue' /* webpackChunkName: "pages/business/stopjob/_job/_code" */))
const _728abdd4 = () => interopDefault(import('../src/pages/a/_alias.vue' /* webpackChunkName: "pages/a/_alias" */))
const _3cf0e482 = () => interopDefault(import('../src/pages/c/_company.vue' /* webpackChunkName: "pages/c/_company" */))
const _443fbeb6 = () => interopDefault(import('../src/pages/job/_job.vue' /* webpackChunkName: "pages/job/_job" */))
const _46050e74 = () => interopDefault(import('../src/pages/jobalarm/_id.vue' /* webpackChunkName: "pages/jobalarm/_id" */))
const _3faeb5b6 = () => interopDefault(import('../src/pages/karriere/_alias.vue' /* webpackChunkName: "pages/karriere/_alias" */))
const _4da61b9c = () => interopDefault(import('../src/pages/press/_alias.vue' /* webpackChunkName: "pages/press/_alias" */))
const _86fa9980 = () => interopDefault(import('../src/pages/t/_topic.vue' /* webpackChunkName: "pages/t/_topic" */))
const _196cf93e = () => interopDefault(import('../src/pages/team/_alias.vue' /* webpackChunkName: "pages/team/_alias" */))
const _01304f97 = () => interopDefault(import('../src/pages/business/_onboardingmode/_job/_code.vue' /* webpackChunkName: "pages/business/_onboardingmode/_job/_code" */))
const _7cfdbf48 = () => interopDefault(import('../src/pages/disable-notification/_user/_hash/_type.vue' /* webpackChunkName: "pages/disable-notification/_user/_hash/_type" */))
const _0b7ade03 = () => interopDefault(import('~/pages/jobs/index.vue' /* webpackChunkName: "" */))
const _7c2fff14 = () => interopDefault(import('~/pages/c/_company.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _4b44e8c8,
    name: "about"
  }, {
    path: "/business",
    component: _d69e25d0,
    meta: {"refreshRate":"high"},
    name: "business"
  }, {
    path: "/c",
    component: _68f427be,
    name: "c"
  }, {
    path: "/complaint",
    component: _ff348d34,
    meta: {"refreshRate":"disable"},
    name: "complaint"
  }, {
    path: "/cv",
    component: _76def748,
    meta: {"refreshRate":"disable"},
    name: "cv"
  }, {
    path: "/disclaimer",
    component: _2ac87f70,
    meta: {"showCookieBanner":"false"},
    name: "disclaimer"
  }, {
    path: "/feedback",
    component: _767c08cc,
    meta: {"refreshRate":"disable"},
    name: "feedback"
  }, {
    path: "/hello",
    component: _3b1a98e6,
    name: "hello"
  }, {
    path: "/help",
    component: _287227d6,
    name: "help"
  }, {
    path: "/howitworks",
    component: _7099f288,
    name: "howitworks"
  }, {
    path: "/imprint",
    component: _37b1a684,
    meta: {"showCookieBanner":"false"},
    name: "imprint"
  }, {
    path: "/invited",
    component: _345293f6,
    name: "invited"
  }, {
    path: "/jobalarm",
    component: _08e4390c,
    name: "jobalarm"
  }, {
    path: "/jobs",
    component: _70d26cae,
    meta: {"trackPageView":"disable"},
    name: "jobs"
  }, {
    path: "/karriere",
    component: _3badeb06,
    name: "karriere"
  }, {
    path: "/lebenslauf-erstellen",
    component: _56a3d562,
    name: "lebenslauf-erstellen"
  }, {
    path: "/linkedInCallBack",
    component: _71950bb8,
    meta: {"refreshRate":"disable"},
    name: "linkedInCallBack"
  }, {
    path: "/press",
    component: _41f34e81,
    name: "press"
  }, {
    path: "/privacy",
    component: _315292fa,
    meta: {"showCookieBanner":"false"},
    name: "privacy"
  }, {
    path: "/references",
    component: _dc3d1446,
    name: "references"
  }, {
    path: "/referral",
    component: _b0b6809c,
    name: "referral"
  }, {
    path: "/snapchat",
    component: _5e2307b7,
    meta: {"refreshRate":"disable"},
    name: "snapchat"
  }, {
    path: "/support",
    component: _1f62974a,
    name: "support"
  }, {
    path: "/team",
    component: _6d5035f5,
    name: "team"
  }, {
    path: "/values",
    component: _458971f7,
    name: "values"
  }, {
    path: "/video",
    component: _83024e8e,
    meta: {"refreshRate":"disable"},
    name: "video"
  }, {
    path: "/business/contact",
    component: _1d7a9de6,
    name: "business-contact"
  }, {
    path: "/business/downloads",
    component: _a043e118,
    name: "business-downloads"
  }, {
    path: "/business/events",
    component: _4d9b1af4,
    name: "business-events"
  }, {
    path: "/business/guide",
    component: _490eda42,
    name: "business-guide"
  }, {
    path: "/business/guide-advertisement",
    component: _21f9a08c,
    meta: {"refreshRate":"disable"},
    name: "business-guide-advertisement"
  }, {
    path: "/business/hr-tipps",
    component: _13ab4fc8,
    name: "business-hr-tipps"
  }, {
    path: "/business/partners",
    component: _3b4b39d0,
    name: "business-partners"
  }, {
    path: "/business/podcasts",
    component: _bf3a8148,
    name: "business-podcasts"
  }, {
    path: "/business/pricing",
    component: _77e9d68c,
    name: "business-pricing"
  }, {
    path: "/business/products",
    component: _f45b7ee4,
    name: "business-products"
  }, {
    path: "/business/recruiting",
    component: _014cd2de,
    name: "business-recruiting"
  }, {
    path: "/business/signup",
    component: _8dd93a7c,
    meta: {"refreshRate":"disable"},
    name: "business-signup"
  }, {
    path: "/business/support",
    component: _a69aebd6,
    name: "business-support"
  }, {
    path: "/business/tup",
    component: _054d7355,
    meta: {"refreshRate":"disable"},
    name: "business-tup"
  }, {
    path: "/business/webinars",
    component: _5530fd94,
    name: "business-webinars"
  }, {
    path: "/jobalarm/settings",
    component: _74c9194e,
    meta: {"refreshRate":"disable"},
    name: "jobalarm-settings"
  }, {
    path: "/lp/ausbildung-oder-abitur-quiz",
    component: _79903cf5,
    meta: {"includedTopLevelDomains":["de"]},
    name: "lp-ausbildung-oder-abitur-quiz"
  }, {
    path: "/lp/ausbildungs-quiz",
    component: _083481f6,
    meta: {"includedTopLevelDomains":["de"]},
    name: "lp-ausbildungs-quiz"
  }, {
    path: "/lp/brutto-netto-rechner/:remuneration?",
    component: _499248de,
    name: "lp-brutto-netto-rechner"
  }, {
    path: "/lp/gehltsverhandlung-quiz",
    component: _57474c75,
    meta: {"includedTopLevelDomains":["at","de"]},
    name: "lp-gehltsverhandlung-quiz"
  }, {
    path: "/lp/haerteste-jobs-quiz",
    component: _03579c56,
    meta: {"includedTopLevelDomains":["at","de"]},
    name: "lp-haerteste-jobs-quiz"
  }, {
    path: "/lp/home-office-typ-quiz",
    component: _57789580,
    meta: {"includedTopLevelDomains":["at","de"]},
    name: "lp-home-office-typ-quiz"
  }, {
    path: "/lp/jobinterviewtraining",
    component: _74329938,
    meta: {"refreshRate":"disable"},
    name: "lp-jobinterviewtraining"
  }, {
    path: "/lp/jobwechsel-quiz",
    component: _5533ea22,
    name: "lp-jobwechsel-quiz"
  }, {
    path: "/lp/karriere-quiz",
    component: _d34f59f2,
    name: "lp-karriere-quiz"
  }, {
    path: "/lp/kellner-quiz",
    component: _8b716372,
    name: "lp-kellner-quiz"
  }, {
    path: "/lp/lehre-oder-studium-quiz",
    component: _be95d50a,
    meta: {"includedTopLevelDomains":["at"]},
    name: "lp-lehre-oder-studium-quiz"
  }, {
    path: "/lp/lehre-quiz",
    component: _f4078d34,
    meta: {"includedTopLevelDomains":["at"]},
    name: "lp-lehre-quiz"
  }, {
    path: "/lp/minijob-quiz",
    component: _4a9c86e2,
    meta: {"includedTopLevelDomains":["de"]},
    name: "lp-minijob-quiz"
  }, {
    path: "/lp/nebenjob-quiz",
    component: _2c207323,
    meta: {"includedTopLevelDomains":["at"]},
    name: "lp-nebenjob-quiz"
  }, {
    path: "/lp/unterrichtsmaterial",
    component: _f109b298,
    name: "lp-unterrichtsmaterial"
  }, {
    path: "/lp/vier-tage-woche-quiz",
    component: _4fcc0fb5,
    name: "lp-vier-tage-woche-quiz"
  }, {
    path: "/lp/virtualconnect",
    component: _5784d4b6,
    name: "lp-virtualconnect"
  }, {
    path: "/lp/vorstellungsgespraech-check",
    component: _58b9b6b5,
    name: "lp-vorstellungsgespraech-check"
  }, {
    path: "/press/press-links",
    component: _10d768c4,
    name: "press-press-links"
  }, {
    path: "/press/press-releases",
    component: _61b848f7,
    name: "press-press-releases"
  }, {
    path: "/business/agreement/application",
    component: _1a680ede,
    name: "business-agreement-application"
  }, {
    path: "/business/lp/active-sourcing",
    component: _c766e1c4,
    meta: {"includedTopLevelDomains":["at"]},
    name: "business-lp-active-sourcing"
  }, {
    path: "/business/lp/do-it-jobs",
    component: _bb80aeb2,
    name: "business-lp-do-it-jobs"
  }, {
    path: "/business/lp/employer-branding-card",
    component: _796bf0c1,
    name: "business-lp-employer-branding-card"
  }, {
    path: "/business/lp/inserate-schalten",
    component: _16ec844f,
    name: "business-lp-inserate-schalten"
  }, {
    path: "/business/lp/kandidaten-suchen",
    component: _d79bb64e,
    name: "business-lp-kandidaten-suchen"
  }, {
    path: "/business/lp/mitarbeiter-finden",
    component: _306791c8,
    name: "business-lp-mitarbeiter-finden"
  }, {
    path: "/business/lp/mitarbeiter-suche",
    component: _5fe120f4,
    name: "business-lp-mitarbeiter-suche"
  }, {
    path: "/business/lp/recruiter-assistent",
    component: _11402d1f,
    name: "business-lp-recruiter-assistent"
  }, {
    path: "/business/lp/termin-vereinbaren",
    component: _38a7a1ec,
    meta: {"refreshRate":"disable"},
    name: "business-lp-termin-vereinbaren"
  }, {
    path: "/business/lp/testimonials",
    component: _47f9dea5,
    meta: {"refreshRate":"disable"},
    name: "business-lp-testimonials"
  }, {
    path: "/business/lp/unsere-loesungen",
    component: _710a1748,
    meta: {"refreshRate":"disable"},
    name: "business-lp-unsere-loesungen"
  }, {
    path: "/",
    component: _6afcc38d,
    meta: {"refreshRate":"high","trackPageView":"disable"},
    name: "index"
  }, {
    path: "/business/downloads/:alias",
    component: _289a4f9f,
    name: "business-downloads-alias"
  }, {
    path: "/business/events/:alias",
    component: _29d24ecd,
    name: "business-events-alias"
  }, {
    path: "/business/hr-tipps/:tag?",
    component: _617b2a56,
    name: "business-hr-tipps-tag"
  }, {
    path: "/business/invite/:invitecode?",
    component: _21a25f39,
    meta: {"refreshRate":"disable"},
    name: "business-invite-invitecode"
  }, {
    path: "/business/partners/:alias",
    component: _45a8707b,
    name: "business-partners-alias"
  }, {
    path: "/business/podcasts/:alias",
    component: _48ab9cb7,
    name: "business-podcasts-alias"
  }, {
    path: "/business/recruiting/:alias",
    component: _6ee0a7bc,
    meta: {"isArticle":"true"},
    name: "business-recruiting-alias"
  }, {
    path: "/business/webinars/:alias",
    component: _9781d3c6,
    name: "business-webinars-alias"
  }, {
    path: "/business/feedback/:job?/:code?",
    component: _0a593b8b,
    meta: {"refreshRate":"disable"},
    name: "business-feedback-job-code"
  }, {
    path: "/business/mon/:job?/:code?",
    component: _98cc0ff0,
    meta: {"refreshRate":"disable"},
    name: "business-mon-job-code"
  }, {
    path: "/business/startjob/:job?/:code?",
    component: _621b4435,
    meta: {"refreshRate":"disable"},
    name: "business-startjob-job-code"
  }, {
    path: "/business/stopjob/:job?/:code?",
    component: _624fddb9,
    name: "business-stopjob-job-code"
  }, {
    path: "/a/:alias?",
    component: _728abdd4,
    meta: {"isArticle":"true"},
    name: "a-alias"
  }, {
    path: "/c/:company",
    component: _3cf0e482,
    meta: {"trackPageView":"disable"},
    name: "c-company"
  }, {
    path: "/job/:job/:uref?",
    component: _443fbeb6,
    meta: {"trackPageView":"disable"},
    name: "job-job"
  }, {
    path: "/jobalarm/:id/:subscriptionId?",
    component: _46050e74,
    name: "jobalarm-id"
  }, {
    path: "/karriere/:alias",
    component: _3faeb5b6,
    meta: {"isArticle":"true"},
    name: "karriere-alias"
  }, {
    path: "/press/:alias",
    component: _4da61b9c,
    meta: {"isArticle":"true"},
    name: "press-alias"
  }, {
    path: "/t/:topic?",
    component: _86fa9980,
    name: "t-topic"
  }, {
    path: "/team/:alias",
    component: _196cf93e,
    name: "team-alias"
  }, {
    path: "/business/:onboardingmode(claimcompany|claimjob|startjobclaim|startclaim|startjobclaimpartner)/:job/:code",
    component: _01304f97,
    meta: {"refreshRate":"disable"},
    name: "business-onboardingmode-job-code"
  }, {
    path: "/disable-notification/:user/:hash/:type/:step?",
    component: _7cfdbf48,
    name: "disable-notification-user-hash-type"
  }, {
    path: "/business/recruiting/:alias(.*)*",
    component: _6ee0a7bc,
    meta: {"isArticle":"true"},
    name: "business-recruiting-alias/business/recruiting/:alias(.*)*"
  }, {
    path: "/amp/a/:alias",
    component: _728abdd4,
    meta: {"isArticle":"true"},
    name: "a-alias/amp/a/:alias"
  }, {
    path: "/karriere/:alias(.*)*",
    component: _3faeb5b6,
    meta: {"isArticle":"true"},
    name: "karriere-alias/karriere/:alias(.*)*"
  }, {
    path: "/Salzburg-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-salzburg-jobs"
  }, {
    path: "/Steyr-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-steyr-jobs"
  }, {
    path: "/Villach-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-villach-jobs"
  }, {
    path: "/Produktion-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-produktion-jobs"
  }, {
    path: "/Wels-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-wels-jobs"
  }, {
    path: "/Trainee-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-trainee-jobs"
  }, {
    path: "/Pflege-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-pflege-jobs"
  }, {
    path: "/Teilzeit-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-teilzeit-jobs"
  }, {
    path: "/Praktikum-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-praktikum-jobs"
  }, {
    path: "/Praktikum-Wien-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-praktikum-wien-jobs"
  }, {
    path: "/Tischler-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-tischler-jobs"
  }, {
    path: "/Sachbearbeitung-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-sachbearbeitung-jobs"
  }, {
    path: "/Schweisser-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-schweisser-jobs"
  }, {
    path: "/Produktionsmitarbeiter-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-produktionsmitarbeiter-jobs"
  }, {
    path: "/Schlosser-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-schlosser-jobs"
  }, {
    path: "/Assistenz-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-assistenz-jobs"
  }, {
    path: "/Verkauf-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-verkauf-jobs"
  }, {
    path: "/Spengler-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-spengler-jobs"
  }, {
    path: "/Administration-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-administration-jobs"
  }, {
    path: "/Assistenz-Wien-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-assistenz-wien-jobs"
  }, {
    path: "/Lehrstelle-Wien-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-lehrstelle-wien-jobs"
  }, {
    path: "/Wien-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-wien-jobs"
  }, {
    path: "/Lehrstelle-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-lehrstelle-jobs"
  }, {
    path: "/Marketing-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-marketing-jobs"
  }, {
    path: "/Marketing-Wien-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-marketing-wien-jobs"
  }, {
    path: "/Baden-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-baden-jobs"
  }, {
    path: "/Buchhaltung-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-buchhaltung-jobs"
  }, {
    path: "/Verkauf-Kundenberatung-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-verkauf-kundenberatung-jobs"
  }, {
    path: "/Reinigung-Facility-Management-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-reinigung-facility-management-jobs"
  }, {
    path: "/B%C3%BCro-Administration-B%C3%BCro-Administration-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-büro-administration-büro-administration-jobs"
  }, {
    path: "/Einkauf-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-einkauf-jobs"
  }, {
    path: "/Einkauf-Wien-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-einkauf-wien-jobs"
  }, {
    path: "/Elektriker-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-elektriker-jobs"
  }, {
    path: "/Entwickler-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-entwickler-jobs"
  }, {
    path: "/Fahrer-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-fahrer-jobs"
  }, {
    path: "/Friseur-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-friseur-jobs"
  }, {
    path: "/Graz-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-graz-jobs"
  }, {
    path: "/Hilfskraft-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-hilfskraft-jobs"
  }, {
    path: "/Hotel-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-hotel-jobs"
  }, {
    path: "/Innsbruck-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-innsbruck-jobs"
  }, {
    path: "/Installateur-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-installateur-jobs"
  }, {
    path: "/Kellner-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-kellner-jobs"
  }, {
    path: "/Kellner-Wien-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-kellner-wien-jobs"
  }, {
    path: "/Klosterneuburg-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-klosterneuburg-jobs"
  }, {
    path: "/Kosmetiker-Jobs",
    component: _0b7ade03,
    meta: {"domain":"at","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-kosmetiker-jobs"
  }, {
    path: "/Stuttgart-Jobs",
    component: _0b7ade03,
    meta: {"domain":"de","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-stuttgart-jobs"
  }, {
    path: "/Leipzig-Jobs",
    component: _0b7ade03,
    meta: {"domain":"de","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-leipzig-jobs"
  }, {
    path: "/Bremen-Jobs",
    component: _0b7ade03,
    meta: {"domain":"de","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-bremen-jobs"
  }, {
    path: "/Darmstadt-Jobs",
    component: _0b7ade03,
    meta: {"domain":"de","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-darmstadt-jobs"
  }, {
    path: "/Dortmund-Jobs",
    component: _0b7ade03,
    meta: {"domain":"de","refreshRate":"disable","trackPageView":"disable"},
    name: "joblist-dortmund-jobs"
  }, {
    path: "/jobs/k/:branch",
    component: _0b7ade03,
    meta: {"trackPageView":"disable","refreshRate":undefined},
    name: "joblist-keyword"
  }, {
    path: "/jobs/l/:location",
    component: _0b7ade03,
    meta: {"trackPageView":"disable","refreshRate":undefined},
    name: "joblist-location"
  }, {
    path: "/jobs/m/:branch/:location",
    component: _0b7ade03,
    meta: {"trackPageView":"disable","refreshRate":undefined},
    name: "joblist-mixed"
  }, {
    path: "/jobs/f/:filter0/:filter1?/:filter2?/:filter3?/:filter4?/:filter5?",
    component: _0b7ade03,
    meta: {"trackPageView":"disable","refreshRate":undefined},
    name: "joblist-filter"
  }, {
    path: "/jobs/kf/:branch/:filter0/:filter1?/:filter2?/:filter3?/:filter4?/:filter5?",
    component: _0b7ade03,
    meta: {"trackPageView":"disable","refreshRate":undefined},
    name: "joblist-keyword-filter"
  }, {
    path: "/jobs/lf/:location/:filter0/:filter1?/:filter2?/:filter3?/:filter4?/:filter5?",
    component: _0b7ade03,
    meta: {"trackPageView":"disable","refreshRate":undefined},
    name: "joblist-location-filter"
  }, {
    path: "/jobs/mf/:branch/:location/:filter0/:filter1?/:filter2?/:filter3?/:filter4?/:filter5?",
    component: _0b7ade03,
    meta: {"trackPageView":"disable","refreshRate":undefined},
    name: "joblist-mixed-filter"
  }, {
    path: "/jobs/search",
    component: _0b7ade03,
    meta: {"trackPageView":"disable","refreshRate":"disable"},
    name: "joblist-search"
  }, {
    path: "/c/:company/k/:branch",
    component: _7c2fff14,
    meta: {"trackPageView":"disable"},
    name: "company-keyword"
  }, {
    path: "/c/:company/l/:location",
    component: _7c2fff14,
    meta: {"trackPageView":"disable"},
    name: "company-location"
  }, {
    path: "/c/:company/m/:branch/:location",
    component: _7c2fff14,
    meta: {"trackPageView":"disable"},
    name: "company-mixed"
  }, {
    path: "/c/:company/f/:filter0?/:filter1?/:filter2?/:filter3?/:filter4?/:filter5?",
    component: _7c2fff14,
    meta: {"trackPageView":"disable"},
    name: "company-filter"
  }, {
    path: "/c/:company/kf/:branch/:filter0?/:filter1?/:filter2?/:filter3?/:filter4?/:filter5?",
    component: _7c2fff14,
    meta: {"trackPageView":"disable"},
    name: "company-keyword-filter"
  }, {
    path: "/c/:company/lf/:location/:filter0?/:filter1?/:filter2?/:filter3?/:filter4?/:filter5?",
    component: _7c2fff14,
    meta: {"trackPageView":"disable"},
    name: "company-location-filter"
  }, {
    path: "/c/:company/mf/:branch/:location/:filter0?/:filter1?/:filter2?/:filter3?/:filter4?/:filter5?",
    component: _7c2fff14,
    meta: {"trackPageView":"disable"},
    name: "company-mixed-filter"
  }, {
    path: "/c/:company/search",
    component: _7c2fff14,
    meta: {"trackPageView":"disable"},
    name: "company-search"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
